import React, {
	useEffect,
	useMemo,
	useState,
	MouseEvent,
} from 'react'
import { useVenti } from 'venti'
import { useNavigate } from 'react-router-dom'
import {
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query'
import { TableColumn } from 'react-data-table-component'
import { ExtendedLoan } from '@matech/thebigpos-sdk'
import { applyMask } from '../../../../services/utils'
import { formatDate } from '../../../../services/helper'
import { getTheme } from '../../../../config'
import { useAppContext } from '../../../../components/AppContext'
import useUser from '../../../../hooks/useUser'
import DataTable, {
	ActionItem,
} from '../../../../components/DataTable'
import PipelineExpandedRow from './PipelineExpandedRow'
import { navigationLinking } from '../../../../services/navigation'
import { TheBigPOSApi } from '../../../../lib/TheBigPOSClient'
import { useAlert } from '../../../../hooks'
import queryKeys from '../../../../services/queryKeys'
import useSearch from '../../../../hooks/useSearch'
import usePagination from '../../../../hooks/usePagination'
import ImportLoanForm from '../forms/ImportLoanForm'
import Dialog from '../../../../components/modals/Dialog'
import { FixMeLater } from '../../../../types'

type LoanTabProps = {
	pageTitle: string
}

const LoansTab = ({ pageTitle }: LoanTabProps) => {
	const { alert } = useAlert()
	const ventiState = useVenti()
	const theme = getTheme()
	const { dataTable: breakpoint } = theme.breakpoints
	const navigate = useNavigate()
	const queryClient = useQueryClient()
	const { isBorrower, isRealtor, isLoanOfficer, isLoanAdmin } =
		useUser()
	const { state } = useAppContext()
	const { user } = state
	const [filteredData, setFilteredData] = useState([])
	const [importModalOpen, setImportModalOpen] = useState(false)
	const { searchText, handleSearchChange, handleClearSearchClick } =
		useSearch({})
	const [criteria, setCriteria] = useState({})

	const {
		pageNumber,
		pageSize,
		sortBy,
		sortDirection,
		handleSort,
		handleRowsPerPageChange,
		handlePageChange,
	} = usePagination<ExtendedLoan>({
		defaultSortBy: 'startDate',
		defaultSortDirection: 'desc',
	})

	const {
		isFetching,
		isRefetching,
		isError,
		data: result,
		refetch,
	} = useQuery({
		queryKey: [
			queryKeys.loans,
			user?.id,
			criteria,
			pageNumber,
			pageSize,
			sortBy,
			sortDirection,
		],
		queryFn: () =>
			// TODO: replace with loan search endpoint
			TheBigPOSApi.getLoansByAccountSetting(criteria, {
				pageNumber,
				pageSize,
				sortBy,
				sortDirection,
			}),
	})

	useEffect(() => {
		// only add to searchCriteria in case we have SearchCriteria + pagination on BE
		if (!searchText || (result && result?.data?.applications)) return
		setCriteria((prev) => ({ ...prev, searchText }))
	}, [searchText, result])

	useEffect(() => {
		if (!isError) return
		alert('There was a problem loading the applications', {
			severity: 'error',
		})
	}, [isError, alert])

	const importLoanMutation = useMutation({
		mutationFn: (loanId: string) => {
			return TheBigPOSApi.importLoanFromLos(loanId)
		},
		onSuccess: async () => {
			alert('The loan was successfully imported', {
				severity: 'success',
			})
			await queryClient.invalidateQueries({
				queryKey: [queryKeys.loans],
			})
			setImportModalOpen(false)
		},
		onError: (err: FixMeLater) => {
			alert(
				err?.data?.message ||
					'Something unexpected happened. Please contact support.',
				{ severity: 'error' }
			)
		},
	})

	const handleImportLoan = async (data: FixMeLater) => {
		const { loanId } = data

		await importLoanMutation.mutateAsync(loanId)
	}

	// TODO: remove when only new code is going to be used
	const columnsOld = useMemo(() => {
		const roleBasedColumns: TableColumn<FixMeLater>[] = [
			{
				name: 'Loan Amount',
				selector: (row: FixMeLater) => row.loanAmount,
				cell: (row: FixMeLater) =>
					row.loanAmount
						? `$${applyMask(row.loanAmount, 'fullCurrency')}`
						: 'TBD',
				sortable: true,
			},
			{
				name: 'Name',
				selector: (row: FixMeLater) =>
					`${row.borrowerFirstName} ${row.borrowerLastName}`,
				cell: (row: FixMeLater) => (
					<div className="flex flex-col">
						<div>
							{row.borrowerFirstName} {row.borrowerLastName}
						</div>
						{row.coBorrowerFirstName && row.coBorrowerLastName && (
							<div className="mt-1">
								{row.coBorrowerFirstName} {row.coBorrowerLastName}
							</div>
						)}
					</div>
				),
				sortable: true,
			},
			{
				name: 'Address',
				hide: breakpoint,
				selector: (row: FixMeLater) => row.subjectPropertyAddress,
				cell: (row: FixMeLater) => {
					const subjectPropertyAddress = []
					if (row.subjectPropertyStreet)
						subjectPropertyAddress.push(row.subjectPropertyStreet)
					if (row.subjectPropertyUnitNumber)
						subjectPropertyAddress.push(
							`${row.subjectPropertyUnitType} ${row.subjectPropertyUnitNumber}`
						)
					if (row.subjectPropertyCity)
						subjectPropertyAddress.push(
							`${row.subjectPropertyCity}, ${row.subjectPropertyState} ${row.subjectPropertyZip}`
						)

					if (subjectPropertyAddress.length > 0) {
						return (
							<div>
								{subjectPropertyAddress.map((line, index) => (
									<div key={index}>{line}</div>
								))}
							</div>
						)
					}

					return 'TBD'
				},
			},
			{
				name: 'Application Date',
				hide: breakpoint,
				selector: (row: FixMeLater) => row.applicationDate,
				sortable: true,
				cell: (row: FixMeLater) =>
					formatDate(row.applicationDate, false),
			},
		]

		if (!isLoanOfficer) {
			roleBasedColumns.splice(2, 0, {
				name: 'Loan Officer',
				hide: breakpoint,
				selector: (row: FixMeLater) => row.loanOfficer,
				cell: (row: FixMeLater) =>
					row.loanOfficer || 'Not yet assigned',
			})
		}

		if (!isBorrower) {
			roleBasedColumns.unshift({
				name: 'Loan Number',
				selector: (row: FixMeLater) => row.loanNumber,
				sortable: true,
				hide: breakpoint,
			})
			roleBasedColumns.push({
				name: 'Status',
				selector: (row: FixMeLater) => row.loanStatus,
				sortable: true,
				hide: breakpoint,
			})
		}

		if (isRealtor) {
			roleBasedColumns.splice(4, 0, {
				name: 'Role',
				hide: breakpoint,
				selector: (row: FixMeLater) => {
					let role = 'Selling Agent'
					const userEmail = user?.email.toLowerCase()
					const sellerAgentEmail =
						row.sellingAgent?.email?.toLowerCase()
					const buyerAgentEmail = row.buyerAgent?.email?.toLowerCase()

					if (
						buyerAgentEmail === userEmail &&
						sellerAgentEmail === userEmail
					) {
						role = 'Dual Agent'
					} else if (buyerAgentEmail === userEmail) {
						role = 'Buying Agent'
					}
					return role
				},
			})
		}

		return roleBasedColumns
	}, [isBorrower, isLoanOfficer, isRealtor, user?.email])

	const columns = useMemo(() => {
		const roleBasedColumns: TableColumn<ExtendedLoan>[] = [
			{
				name: 'Loan Amount',
				selector: (row: ExtendedLoan) => row.totalLoanAmount || '',
				cell: (row: ExtendedLoan) =>
					row.totalLoanAmount
						? `$${applyMask(row.totalLoanAmount, 'fullCurrency')}`
						: 'TBD',
				sortField: 'totalLoanAmount',
				sortable: true,
			},
			{
				name: 'Name',
				selector: (row: ExtendedLoan) =>
					`${row.borrowerContact?.firstName} ${row.borrowerContact?.lastName}`,
				cell: (row: ExtendedLoan) => (
					<div className="flex flex-col">
						<div>
							{row.borrowerContact?.firstName}{' '}
							{row.borrowerContact?.lastName}
						</div>
						{row.coBorrowerContact?.firstName &&
							row.coBorrowerContact?.lastName && (
								<div className="mt-1">
									{row.coBorrowerContact?.firstName}{' '}
									{row.coBorrowerContact?.lastName}
								</div>
							)}
					</div>
				),
			},
			{
				name: 'Address',
				hide: breakpoint,
				selector: (row: ExtendedLoan) =>
					row.propertyAddress?.street || '',
				cell: (row: ExtendedLoan) => {
					const propertyAddress = []
					if (row.propertyAddress?.street)
						propertyAddress.push(row.propertyAddress?.street)
					if (row.propertyAddress?.unit)
						propertyAddress.push(row.propertyAddress?.unit)
					if (row.propertyAddress?.city)
						propertyAddress.push(
							`${row.propertyAddress?.city}, ${row.propertyAddress?.state} ${row.propertyAddress?.postalCode}`
						)

					if (propertyAddress.length > 0) {
						return (
							<div>
								{propertyAddress.map((line, index) => (
									<div key={index}>{line}</div>
								))}
							</div>
						)
					}

					return 'TBD'
				},
			},
			{
				name: 'Application Date',
				hide: breakpoint,
				selector: (row: ExtendedLoan) => row.startDate || '',
				sortable: true,
				sortField: 'startDate',
				cell: (row: FixMeLater) => formatDate(row.startDate, false),
			},
		]

		if (!isLoanOfficer) {
			roleBasedColumns.splice(2, 0, {
				name: 'Loan Officer',
				hide: breakpoint,
				selector: (row: FixMeLater) =>
					row.loanOfficer
						? row.loanOfficer?.Name
						: 'Not yet assigned',
			})
		}

		if (!isBorrower) {
			roleBasedColumns.unshift({
				name: 'Loan Number',
				hide: breakpoint,
				selector: (row: FixMeLater) => row.loanNumber,
				sortable: true,
				sortField: 'loanNumber',
			})
			roleBasedColumns.push({
				name: 'Status',
				hide: breakpoint,
				selector: (row: ExtendedLoan) => row.status || '',
				sortable: true,
				sortField: 'status',
			})
		}

		if (isRealtor) {
			roleBasedColumns.splice(4, 0, {
				name: 'Role',
				hide: breakpoint,
				selector: (row: FixMeLater) => {
					let role = 'Selling Agent'
					const userEmail = user?.email.toLowerCase()
					const sellerAgentEmail =
						row.sellerAgentContact?.email?.toLowerCase()
					const buyerAgentEmail =
						row.buyerAgentContact?.email?.toLowerCase()

					if (
						buyerAgentEmail === userEmail &&
						sellerAgentEmail === userEmail
					) {
						role = 'Dual Agent'
					} else if (buyerAgentEmail === userEmail) {
						role = 'Buying Agent'
					}
					return role
				},
			})
		}

		return roleBasedColumns
	}, [isBorrower, isLoanOfficer, isRealtor, user?.email])

	useEffect(() => {
		const applicationData = result?.data?.applications || []
		if (searchText !== '') {
			const newFilteredData = !searchText
				? applicationData
				: applicationData.filter(
						(d: FixMeLater) =>
							d?.borrowerFirstName
								?.toLowerCase()
								.includes(searchText.toLowerCase()) ||
							d.borrowerLastName
								?.toLowerCase()
								.includes(searchText.toLowerCase()) ||
							`${d.borrowerFirstName} ${d.borrowerLastName}`
								.toLowerCase()
								.includes(searchText.toLowerCase()) ||
							d.loanOfficer
								?.toLowerCase()
								.includes(searchText.toLowerCase()) ||
							d.loanAmount
								?.toString()
								.includes(searchText.toLowerCase()) ||
							`${d.subjectPropertyCity} ${d.subjectPropertyState} ${d.subjectPropertyStreet}`
								.toLowerCase()
								.includes(searchText.toLowerCase()) ||
							d.loanNumber?.includes(searchText)
					)
			setFilteredData(newFilteredData)
		} else {
			setFilteredData(applicationData)
		}
	}, [result, searchText])

	const onRowClicked = async (row: FixMeLater) => {
		await ventiState.unset(theme.storageKeys.loanData)
		await ventiState.unset(theme.storageKeys.loanTasks)
		await ventiState.unset(theme.storageKeys.loanDocs)
		await ventiState.set(theme.storageKeys.loanId, row?.loanID) // not sure how row would be null but getting logs in sentry
		navigate(`/${navigationLinking.Portal}`)
	}

	const actionItems: ActionItem<FixMeLater>[] = [
		{
			name: isLoanAdmin ? 'Impersonate' : 'View Application',
			onClick: (e: MouseEvent, row: FixMeLater) => onRowClicked(row),
		},
	]

	const progressPending = isFetching || isRefetching

	return (
		<>
			{result && result?.data?.count ? (
				<DataTable
					data={result?.data?.rows || []}
					columns={columns}
					title={pageTitle}
					pagination
					progressPending={progressPending}
					onRefreshClick={refetch}
					fixedHeader
					actionItems={actionItems}
					expandableRowsComponent={PipelineExpandedRow}
					onSearchChange={handleSearchChange}
					searchText={searchText}
					onClearSearchClick={handleClearSearchClick}
					onRowClicked={onRowClicked}
					onChangePage={handlePageChange}
					onChangeRowsPerPage={handleRowsPerPageChange}
					onSort={handleSort}
					// addNewBtn={{
					// 	text: 'Import loan',
					// 	onClick: () => setImportModalOpen(true),
					// 	disabled: false,
					// }}
					defaultSortFieldId={sortBy}
					defaultSortAsc={sortDirection === 'asc'}
					paginationTotalRows={result?.data?.count}
					paginationServer
				/>
			) : (
				<DataTable
					data={filteredData}
					columns={columnsOld}
					defaultSortAsc={false}
					title={pageTitle}
					defaultSortFieldId="applicationDate"
					pagination
					progressPending={progressPending}
					onRefreshClick={refetch}
					fixedHeader
					actionItems={actionItems}
					expandableRowsComponent={PipelineExpandedRow}
					onSearchChange={handleSearchChange}
					searchText={searchText}
					onClearSearchClick={handleClearSearchClick}
					onRowClicked={onRowClicked}
					// addNewBtn={{
					// 	text: 'Import loan',
					// 	onClick: () => setImportModalOpen(true),
					// 	disabled: false,
					// }}
				/>
			)}
			<Dialog
				open={importModalOpen}
				title="Import loan"
				onClose={() => setImportModalOpen(false)}
				fullWidth
			>
				<ImportLoanForm onSubmit={handleImportLoan} />
			</Dialog>
		</>
	)
}

export default LoansTab
